<template>
  <div>
    <loading :enable="!load"/>
    <div v-if="load" class="pb-5">
      <b-row class="card">
        <div class="w-100">
          <h5 class="title-card">
            <i class="fas fa-file"></i> Projeto
          </h5>
        </div>

        <div class="w-100 d-flex justify-content-between p-3">
          <div class="w-100 d-flex justify-content-end">
            <b-link :href="link" class="text-light" target="_blank">
              <b-button variant="info">Visualizar PDF</b-button>
            </b-link>
          </div>
        </div>

        <div class="w-100 p-3">
          <div class="row">
            <div class="col-md-3">
              <div class="w-100 position-relative bg-primary p-4 text-center text-light">
                <h3>Valor</h3>
                <h2>{{ project.price | currency }}</h2>
                <div class="hover" v-b-modal.edit-project v-if="$acl.check('isAdmin')">
                  <b-button variant="default">
                    <i class="fas fa-pen"></i> Editar valor
                  </b-button>
                </div>
              </div>
            </div>
            <div class="col-md-3">
              <div class="w-100 bg-warning p-4 text-center text-light">
                <h3>Kwh</h3>
                <h2>{{ parseInt(project_product.estimate_power) }}</h2>
              </div>
            </div>
            <div class="col-md-3">
              <div class="w-100 bg-info p-4 text-center text-light">
                <h3>Painéis</h3>
                <h2>{{ parseInt(project_product.panel_count) }}</h2>
              </div>
            </div>
            <div class="col-md-3">
              <div class="w-100 bg-success p-4 text-center text-light">
                <h3>Comissão</h3>
                <h2>{{ agent_comission | currency }}</h2>
              </div>
            </div>
          </div>
          <div class="w-100 mt-5">
            <b-row>
              <b-col lg="5">
                <span>Cliente</span> <br>
                <h4>{{ project.client.name }}</h4>
              </b-col>
              <b-col lg="4">
                <span>Email</span> <br>
                <h4>{{ project.client.email }}</h4>
              </b-col>
              <b-col lg="3">
                <span>Contato</span> <br>
                <h4>{{ project.client.phone_mobile }}</h4>
              </b-col>
            </b-row>
            <b-row class="mt-3">
              <b-col lg="5">
                <span>Documento</span> <br>
                <h4>{{ project.client.document }}</h4>
              </b-col>
              <b-col lg="4">
                <span>Cidade</span> <br>
                <h4>{{ project.client.addresses[0].city.name }}</h4>
              </b-col>
              <b-col lg="3">
                <span>Estado</span> <br>
                <h4>{{ project.client.addresses[0].city.province.name }}</h4>
              </b-col>
            </b-row>
          </div>
        </div>
      </b-row>

      <b-row class="card mt-5" v-if="agent">
        <div class="w-100">
          <h5 class="title-card">
            <i class="fas fa-user"></i> Agente
          </h5>
        </div>
        <div class="w-100 p-3">
          <b-row>
            <b-col lg="5">
              <span>Nome</span><br>
              <h4>{{ agent.name }}</h4>
            </b-col>
            <b-col lg="4">
              <span>Email</span><br>
              <h4>{{ agent.email }}</h4>
            </b-col>
            <b-col lg="3">
              <span>Contato</span><br>
              <h4>{{ agent.phone_mobile }}</h4>
            </b-col>
          </b-row>
          <!-- <b-row class="mt-3 align-items-center">
            <b-col lg="4">
              <b-form-group label="Porcentagem sobre a venda">
                <b-input-group append="%">
                  <b-form-input v-model="project.agent_percentage" type="number" max="10"></b-form-input>
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col>
              <b-button
              variant="primary"
              :disabled="$v.project.$invalid" @click="confirmChange" class="mt-2"><i class="fas fa-pen"></i> Alterar</b-button>
            </b-col>
          </b-row> -->
        </div>
      </b-row>

      <b-row class="card mt-5" v-if="discounts.length  > 0">
        <div class="w-100">
          <h5 class="title-card">
            <i class="fas fa-info"></i> Acompanhamento do Projeto
          </h5>
        </div>

        <div class="w-100 p-3" v-if="all_status_departments.length > 0">
          <h5 class="pt-3">Status por departamento</h5>
          <b-table
          :fields="columsTableAccompaniments"
          :items="all_status_departments"
          bordered
          responsive>
          </b-table>
        </div>
        <div class="w-100 p-3" v-if="discounts.length > 0">
          <h5 class="pt-3">Descontos aplicados</h5>
          <b-table
          :fields="columsTableDiscounts"
          :items="discounts"
          bordered
          responsive></b-table>
        </div>
      </b-row>

      <b-row class="card mt-5">
        <div class="w-100">
          <h5 class="title-card">
            <i class="fas fa-solar-panel"></i> Kit
          </h5>
        </div>

        <!-- <div class="w-100 d-flex justify-content-between p-3">
          <div class="w-100">
            <b-button
              variant="warning"
              :to="{ name: 'project-edit-kit', params: { project: id } }"
            >
              <i class="fas fa-pen"></i>  Editar kit
            </b-button>
          </div>
        </div> -->

        <div class="w-100 px-3">
          <b-row>
            <b-col lg="12">
              <span>Descrição</span> <br>
              <h4>{{ product.descricao }}</h4>
            </b-col>
          </b-row>
          <b-row class="mt-3">
            <b-col lg="6">
              <span>Telhado</span><br>
              <h4>{{ product.telhado }}</h4>
            </b-col>
            <b-col lg="3">
              <span>Potência</span><br>
              <h4>{{ product.potencia }}</h4>
            </b-col>
            <b-col lg="3">
              <span>Peso sobre o telhado</span><br>
              <h4>{{ product.peso }} Kg</h4>
            </b-col>
          </b-row>
        </div>
        <hr>
        <div class="w-100 p-3">
          <h3>Componentes do Kit</h3>
          <b-table
          :fields="columsTableComponents"
          :items="project_components"
          bordered
          responsive></b-table>
        </div>
      </b-row>

      <b-modal
        ref="edit-project"
        cancel-title="Fechar"
        id="edit-project"
        ok-title="Confirmar"
        title="Aplicar desconto"
        @ok="update"
      >
        <b-row>
          <b-col>
            <b-form-group label="(%) de desconto">
              <b-input-group append="%">
                <b-form-input
                :class="{ 'form-group--error': $v.payload.percentage_discount.$error }"
                v-model="$v.payload.percentage_discount.$model"
                :state="$v.payload.percentage_discount.$dirty ?
                !$v.payload.percentage_discount.$error : null"
                />
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="Valor do desconto">
              <h5 class="bg-warning p-2">
                {{ value_discount | currency }}
              </h5>
            </b-form-group>
          </b-col>
          <b-col lg="12">
            <div class="d-flex justify-content-center">
              <div class="p-3 text-center bg-info text-light">
                <h5>Valor com desconto</h5>
                {{ price_final | currency }}
              </div>
            </div>
          </b-col>
        </b-row>
      </b-modal>
    </div>

  </div>
</template>

<script>
import { required, maxValue, decimal } from 'vuelidate/lib/validators';
import ProjectService from '@/Services/ProjectService';
import MessagesMixin from '@/mixins/messages-mixin';
import QuestionMixin from '@/mixins/question-mixin';
import ErrorMixin from '@/mixins/error-mixin';

export default {
  props: ['id'],
  data() {
    return {
      load: false,
      agent_percentage_original: 0,
      columsTableComponents: ProjectService.getFieldsTableComponents(),
      columsTableAccompaniments: ProjectService.getFieldsTableAccompaniment(),
      columsTableDiscounts: ProjectService.getFieldsTableDiscounts(),
      project: {},
      project_product: {},
      project_components: {},
      product: {},
      agent: {},
      link: `${process.env.VUE_APP_PDF_LINK}${this.id}`,
      user: JSON.parse(localStorage.getItem('user')),
      all_status_departments: [],
      discounts: [],
      payload: {
        percentage_discount: '',
      },
    };
  },
  computed: {
    agent_comission() {
      return parseFloat(this.project.price) * (this.project.agent_percentage / 100);
    },
    value_discount() {
      return parseFloat(this.project.price) * (this.payload.percentage_discount / 100);
    },
    price_final() {
      return parseFloat(this.project.price) - this.value_discount;
    },
  },
  validations: {
    payload: {
      percentage_discount: { required, maxValue: maxValue(100), decimal },
    },
  },
  mixins: [MessagesMixin, QuestionMixin, ErrorMixin],
  methods: {
    async getProject() {
      const { data } = await ProjectService.get(this.id);
      this.project = data.project;
      this.project_product = data.project_product;
      this.product = data.product;
      this.agent = data.project.agent;
      this.load = true;
      this.agent_comission = parseFloat(this.project.price) * (this.project.agent_percentage / 100);

      this.project_components = data.product_components.map((item) => ({
        quantity: item.quantity * this.project_product.quantity,
        description: item.description,
      }));

      this.discounts = data.discounts.map((item) => ({
        user: item.user.agent !== null ? item.user.agent.name : item.user.name,
        value_project: this.$options.filters.currency(item.price),
        price_final: this.$options.filters.currency(item.price_with_discount),
        value_discount: this.$options.filters.currency(item.discount_value),
        percentage_discount: `${item.percentage_discount / 1}%`,
      }));

      Object.keys(data.all_status_departments).forEach((key) => {
        if (data.all_status_departments[key] !== null) {
          this.all_status_departments.push({
            department: data.all_status_departments[key].status.department.name,
            status: data.all_status_departments[key].status.name,
            operator: data.all_status_departments[key].user.name,
          });
        }
      });
    },

    confirmChange() {
      this.$toast.question(
        'Deseja realmente alterar a sua porcentagem de comissão sobre a venda ?',
        'Atenção',
        this.getQuestionObj(this.update, '', 'Projeto alterado'),
      );
    },

    confirmInspection() {
      this.$toast.question(
        'Deseja realmente solicitar uma vistoria técnica ?',
        'Atenção',
        this.getQuestionObj(this.update, '', 'Vistoria solicitada'),
      );
    },

    requestInspection() {
    },

    async update() {
      const payload = {
        id: this.id,
        project_discount: {
          price: this.project.price,
          project_id: this.id,
          price_with_discount: this.price_final,
          discount_value: this.value_discount,
          user_id: this.user.id,
          percentage_discount: parseFloat(this.payload.percentage_discount),
        },
      };

      try {
        this.$v.payload.$touch();
        if (!this.$v.payload.$invalid) {
          await ProjectService.edit(payload);
          this.getProject();
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
  created() {
    this.getProject();
  },
};
</script>
<style lang="css" scoped>
  span{
    color: #ff904d;
  }

  .hover{
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: #0000009e;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff !important;
    opacity: 0;
  }

  .hover:hover{
    opacity: 1;
    transition: all ease-in-out .5s;
  }

  .hover button {
      color: #fff;
  }
</style>
